import React, { useRef, useState, useEffect } from "react";
import { Link, useStaticQuery } from "gatsby";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { Check, X } from "react-feather";
import Logo from "../components/logo-svg";
import { useHiddenmsClassCity } from "../hooks/useHiddenmsClass";
import { useMatchHiddenClass } from "../hooks/useMatchHiddenClass";
import { getLocPhysicalAndCity as valueTrackParams } from "../helpers/adRefTracking";
import { TOTAL_REVIEW_COUNT, REVIEW_AVG } from "../consts";
import ServicesOffered from "./Footer/ServicesOffered";
import YelpIcon from "../assets/icons/yelp";
import FacebookIcon from "../assets/icons/facebook";
import InstagramIcon from "../assets/icons/instagram";
import GoogleIcon from "../assets/icons/google";
import YoutubeIcon from "../assets/icons/youtube";
import GuildqualityIcon from "../assets/icons/guildquality";
import FooterAreasServedList from "./footer-areas-served";

const companyname = "West Coast Hydronics & Plumbing";
const companynamefull = "West Coast Hydronics & Plumbing, Inc";
const license = "-";
const altslogan = "#1 Premier Plumbing Serving Sacramento";
const email = "info@allplumbingsolutionsca.com";
const phonenumber = "(916) 587-6859";

const LegalLinks = styled.div`
  margin-top: 5px;
  a {
    color: #989898;

    margin-right: 10px;
    font-size: 0.9em;
    &:hover {
      color: #fff;
    }
  }
`;
const CopyrightContainer = styled.div`
  display: flex;
  align-items: center;

  border-top: 1px solid #ececec;
  padding: 1em 0;
  margin-top: 1.2em;

  @media (max-width: 767px) {
    display: block;
    text-align: center;
  }
`;
const CopyrightText = styled.div`
  color: #888;
  margin: 0;
  padding: 0.5em;
`;

const WhyUsUnorderedList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
  li {
    list-style-type: none;
    padding: 0;
    padding-left: 20px;
    line-height: 1.4;
    position: relative;
    svg {
      border-radius: 50%;
      padding-top: 5px;
      stroke-width: 4px;
      margin-left: -24px;
      padding: 4px;
      position: absolute;
      top: 0;
      @media (max-width: 767px) {
        top: -2px !important;
      }
    }
  }
`;

const SocialMediaIcon = styled.div`
  path {
    fill: #777;
  }
  padding: 0.5em 0;
  a {
    svg {
      height: 24px;
    }
  }
`;

const SocialMediaIcons = styled.div`
display: flex;
align-items: center;
justify-content: space-around;
max-width: 300px;
margin: 0 auto;
padding: 1em 0;
  @media (min-width: 767px) {
    padding-right: 3em;{isHomePage ? (
      <>
        <br />
        <span>
          {' '}
          Web Design &amp; Marketing by{' '}
          <a
            target="_blank"
            href="https://www.webperfex.com/?utm_source=westcoasthydronics&utm_medium=referral"
            style={{ color: 'rgb(136, 136, 136)' }}
          >
            WebPerfex
          </a>
        </span>
      </>
    ) : null}
  }
`;

const Footer = ({ isHomePage = false }) => {
  const footerRefs = useHiddenmsClassCity({
    handler: (ref) => {
      const className = ref.current.classList;
      ref.current.className = className + " hiddenms";
    },
  });
  const sidingCitiesLinksRef = useRef();
  const sidingCitiesLinks = useMatchHiddenClass({
    matchFunction: () => !!valueTrackParams().campaigns,
  });
  sidingCitiesLinks.push(sidingCitiesLinksRef);
  const addressRef = useRef();
  footerRefs.push(addressRef);

  const WhyUs = (props) => {
    return (
      <>
        <h4 className=" text-footer-dark text-lg lg:text-xl mb-8">Why Choose West Coast Hydronics & Plumbing?</h4>
        <WhyUsUnorderedList>
          {props.siteMetadata.whyUs.map((item, index) => (
            <li key={index}>
              <Check size={20} className="text-color-primary" />
              {item}
            </li>
          ))}
        </WhyUsUnorderedList>
      </>
    );
  };

  const AreasServed = (props) => {
    let { serviceArea } = props;

    return (
      <>
                <ul className="mt-2 leading-relaxed p-0 list-none flex flex-wrap">
          {serviceArea &&
            serviceArea.edges.map((item, index) => {
              return (

                

                <li className="pb-2 text-base leading-6 text-gray-700 flex items-center flex-grow w-1/2" key={index}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-brand-500 w-5 h-5 mr-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  {
                    <Link
                      to={`/water-heaters-${item.node.slug}/`}
                      title={item.node.location + " water heaters"}
                    >
                      {item && item.node && item.node.location + ", CA"}
                    </Link>
                  }
                </li>
              );
            })}
        </ul>
        <Link to={"/cities/"} className="mt-2  block text-sm text-gray-700 font-semibold font-display">
          View Complete Service Area
        </Link>
      </>
    );
  };

  const FooterColumms = (footerData) => {
    const footerColumns = footerData.data.map((footer, index) => (
      <FooterColumm
        key={index}
        footerInfo={footer}
        columnsCount={footerData.data.length}
        siteData={footerData.siteData.site}
        serviceArea={footerData.siteData.allContentfulServiceArea}
      />
    ));
    return footerColumns;
  };

  const FooterMenuLink = ({ menuItem, index, remodelingRefs }) => {
    const menuItemRef = useRef(null);
    if (menuItem.title.includes("Remodeling")) {
      remodelingRefs.push(menuItemRef);
    }
    return (
      <li key={index} className="pb-1 text-base leading-6 text-gray-700 flex items-center">
        <svg
          className="text-brand-500 w-5 h-5 mr-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>

        <Link
          to={menuItem.url ? menuItem.url : "#"}
          title={menuItem.title}
          activeClassName={"active"}
          ref={menuItemRef}
        >
          {menuItem.title}
        </Link>
      </li>
    );
  };

  const FooterColumm = (footerInfo) => {
    const remodelingRefs = useHiddenmsClassCity();
    if (
      footerInfo.footerInfo.node &&
      footerInfo.footerInfo.node.html &&
      footerInfo.footerInfo.node.html.html.includes("{{CompanyInfo}}")
    ) {
      return (
        <div
          className={"mb-4 col-12 col-md-" + 12 / footerInfo.columnsCount}
          style={{
            order: footerInfo.footerInfo && footerInfo.footerInfo.node && footerInfo.footerInfo.node.order,
          }}
        >
          <CompanyInfoColumn siteMetadata={footerInfo.siteData.siteMetadata} />
        </div>
      );
    }
    if (
      footerInfo.footerInfo.node &&
      footerInfo.footerInfo.node.html &&
      footerInfo.footerInfo.node.html.html.includes("{{WhyUs}}")
    ) {
      return (
        <div
          className={"mb-4 col-12 col-md-" + 12 / footerInfo.columnsCount}
          style={{
            order: footerInfo.footerInfo && footerInfo.footerInfo.node && footerInfo.footerInfo.node.order,
          }}
        >
          <WhyUs siteMetadata={footerInfo.siteData.siteMetadata} />
        </div>
      );
    }
    if (
      footerInfo.footerInfo.node &&
      footerInfo.footerInfo.node.html &&
      footerInfo.footerInfo.node.html.html.includes("{{AreasServed}}")
    ) {
      return (
        <div
          className={"mb-4 col-12 col-md-" + 12 / footerInfo.columnsCount}
          style={{
            order: footerInfo.footerInfo && footerInfo.footerInfo.node && footerInfo.footerInfo.node.order,
          }}
        >
          <AreasServed serviceArea={footerInfo.serviceArea} />
        </div>
      );
    }

    if (footerInfo.footerInfo.node.menuLinks) {
      const footerLinksMapped = footerInfo.footerInfo.node.menuLinks.map((menuItem, index) => (
        <FooterMenuLink key={index} menuItem={menuItem} index={index} remodelingRefs={remodelingRefs} />
      ));
      return (
        <div
          className={"mb-4 col-12 col-md-" + 12 / footerInfo.columnsCount}
          style={{
            order: footerInfo.footerInfo && footerInfo.footerInfo.node && footerInfo.footerInfo.node.order,
          }}
        >
          <h4 className=" text-footer-dark text-lg lg:text-xl mb-8">{footerInfo.footerInfo.node.title}</h4>
          <ul className="mt-2 leading-relaxed p-0  list-none space-y-2">{footerLinksMapped}</ul>
        </div>
      );
    }

    return (
      <div
        className={"mb-4 col-12 col-md-" + 12 / footerInfo.columnsCount}
        style={{
          order: footerInfo.footerInfo && footerInfo.footerInfo.node && footerInfo.footerInfo.node.order,
        }}
      >
        <h4 className="text-footer-dark">{footerInfo.footerInfo.node.title}</h4>
        {footerInfo.footerInfo.node && footerInfo.footerInfo.node.html && footerInfo.footerInfo.node.html.html ? (
          <div
            dangerouslySetInnerHTML={{
              __html: footerInfo.footerInfo.node.html.html,
            }}
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  const CompanyInfoColumn = (props) => {
    let { siteMetadata } = props;

    return (
      <>
        <div className="row m-0">
          <div className="col-12 col-md-12 mb-4">
            <Logo hideLogoText={true} />
          </div>

          <div className="col-6 col-md-12 mb-4 px-1">
            {" "}
            <SocialMediaIcons>
              {siteMetadata.social.yelp ? (
                <SocialMediaIcon>
                  <a href={siteMetadata.social.yelp} target="_blank" rel="noopener noreferrer nofollow">
                    <YelpIcon />
                  </a>
                </SocialMediaIcon>
              ) : (
                ""
              )}
              {siteMetadata.social.google ? (
                <SocialMediaIcon>
                  <a href={siteMetadata.social.google} target="_blank" rel="noopener noreferrer nofollow">
                    <GoogleIcon />
                  </a>
                </SocialMediaIcon>
              ) : (
                ""
              )}

              {siteMetadata.social.google ? (
                <SocialMediaIcon>
                  <a href={siteMetadata.social.google} target="_blank" rel="noopener noreferrer nofollow">
                    <GoogleIcon />
                  </a>
                </SocialMediaIcon>
              ) : (
                ""
              )}

              {siteMetadata.social.yelp ? (
                <SocialMediaIcon>
                  <a href={siteMetadata.social.yelp} target="_blank" rel="noopener noreferrer nofollow">
                    <YelpIcon />
                  </a>
                </SocialMediaIcon>
              ) : (
                ""
              )}
            </SocialMediaIcons>
          </div>

          <CopyrightText className="font-normal">
            &copy; {new Date().getFullYear()} {data.site.siteMetadata.title}, Inc. All Rights Reserved. Lic #:{" "}
            {siteMetadata.companyInfo.license}
            <br />
            <span itemScope className="rating-desc" itemType="https://schema.org/Product">
              <span itemProp="name">{data.site.siteMetadata.title}</span>{" "}
              <span itemScope itemProp="aggregateRating" itemType="https://schema.org/AggregateRating">
                rated <span itemProp="ratingValue">{REVIEW_AVG}</span> / 5 based on{" "}
                <span itemProp="reviewCount">{TOTAL_REVIEW_COUNT}</span> reviews.
              </span>
            </span>
            {isHomePage ? (
              <>
                <br />
                <span style={{ fontSize: "13px" }}>
                  {" "}
                  Web Design &amp; Marketing by{" "}
                  <a
                    target="_blank"
                    href="https://www.webperfex.com/?utm_source=westcoasthydronics&utm_medium=referral"
                    style={{ color: "rgb(136, 136, 136)", fontSize: "13px" }}
                  >
                    WebPerfex
                  </a>
                </span>
              </>
            ) : null}
            <br />
            <LegalLinks>
              <a href="/privacy-policy/" title="Learn about our commitment to privacy" rel="nofollow">
                Privacy Policy
              </a>
              <a href="/contact/" title="Contact Us" rel="nofollow">
                Contact Us
              </a>
            </LegalLinks>
          </CopyrightText>

          <div className="col-12 col-md-12 hidden">
            <h4 className="text-footer-dark mb-1">{siteMetadata.companyInfo.phone}</h4>

            <span className="text-base text-gray-500 block font-normal">
              <span ref={addressRef}>Citrus Heights, CA 95610</span>
            </span>
          </div>
        </div>
      </>
    );
  };

  const areasServedPopupOpenRef = useRef();
  const [areasServedPopupOpen, setAreasServedPopupOpen] = useState(false);
  const toggleReviewsModal = (state) => {
    setAreasServedPopupOpen(state);
  };

  useEffect(() => {
    if (areasServedPopupOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [areasServedPopupOpen]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        toggleReviewsModal(false);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }, [ref, handler]);
  }

  useOnClickOutside(areasServedPopupOpenRef, () => toggleReviewsModal(false));

  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          title
          whyUs
          companyInfo {
            phone
            email
            addressLine1
            addressLine2
            city
            state
            zip
            license
          }
          social {
            yelp
            facebook
            instagram
            youtube
            google
            guildquality
          }
        }
      }

      allContentfulServiceArea(filter: { featuredCity: { eq: true } }, sort: { fields: location, order: ASC }) {
        edges {
          node {
            location
            id
            slug 
          }
        }
      }
      allContentfulServiceAreaAll: allContentfulServiceArea(sort: { fields: location, order: ASC }) {
        edges {
          node {
            location
            id
            slug 
          }
        }
      }
      kitchenCities: allContentfulServiceArea(
        sort: { fields: location, order: ASC }
        filter: { marketSegment: { nin: "bayarea" }, includeInFooter: { eq: true } }
      ) {
        edges {
          node {
            location
            slug
            marketSegment
            includeInFooter
          }
        }
      }
    }
  `);

  //assuming length of array equals columns intended
  let footerMenu = [];

  let { siteMetadata } = data.site;

  return (
    <>
      <div className={`modal-bg-overlay ${areasServedPopupOpen ? "active" : null}`} />
      <div
        className={` overflow-y-scroll  h-full standard-modal ${areasServedPopupOpen ? "active" : null}`}
        ref={areasServedPopupOpenRef}
      >
        <div className="flex items-center justify-between px-8 py-6">
          <div className="font-semibold text-black text-2xl">Areas Served</div>
          <button
            type="button"
            onClick={() => toggleReviewsModal(!areasServedPopupOpen)}
            className="border-0 bg-white hover:gvd-main-bg-darker cursor-pointer rounded-full w-10 h-10 flex text-black items-center justify-center"
          >
            {" "}
            <X size={28} />
          </button>
        </div>
        <div className="px-8">
          <FooterAreasServedList />
        </div>
      </div>

      <div className="py-12 bg-gradient-to-b from-white to-gray-100">
        <div className=" px-4 md:px-6 py-4 lg:py-0 max-w-7xl mx-auto">
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 xl:gap-12">
            {/* <FooterColumms data={defaultFooterMenu} siteData={data} /> */}

            <div className="">
              <Link to="/" className="flex" title={"West Coast Hydronics & Plumbing"}>
                <div style={{ width: "120px" }} className="font-bold text-lg font-display mb-4">
                  <Logo />
                </div>
              </Link>
              {siteMetadata.companyInfo.license ? (
                <span className="block py-2">
                  <span className="block text-gray-800 font-semibold font-display">Licensed &amp; Insured</span>{" "}
                  <span className="block text-gray-600">Contractors License #{siteMetadata.companyInfo.license}</span>
                </span>
              ) : null}

              <span className="block py-2">
                <span className="block text-gray-800 font-semibold font-display">Contact Us</span>{" "}
                <span className="block text-gray-600">
                  {phonenumber}
                  <a href={`mailto:${email}`} className="block">
                    {email}
                  </a>
                </span>
              </span>
              <span className="block py-2">
                <span className="block text-gray-800 font-semibold font-display">Location</span>{" "}
                <span className="block text-gray-600">
                {data.site.siteMetadata.companyInfo.addressLine1}<br/>
                  Sacramento, CA 95841
                </span>
              </span>
            </div>

            <div>
              <div className="md:mt-0 mt-6">
                <h4 className="text-sm leading-5 font-bold tracking-wider text-gray-700 uppercase font-display">
                  Why Us
                </h4>
                <ul className="mt-2 leading-relaxed p-0  list-none space-y-2">
                  <li className="pb-1 text-base leading-6 text-gray-700 flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-brand-500 w-5 h-5 mr-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    No Hidden Costs, Free Quotes
                  </li>
                  <li className="pb-1 text-base leading-6 text-gray-700 flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-brand-500 w-5 h-5 mr-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    On-Time Service
                  </li>
                  <li className="pb-1 text-base leading-6 text-gray-700 flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-brand-500 w-5 h-5 mr-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Local, Family-Owned &amp; Operated
                  </li>
                 
                  <li className="pb-1 text-base leading-6 text-gray-700 flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-brand-500 w-5 h-5 mr-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Reliable, Experienced, Certified
                  </li>
                  <li className="pb-1 text-base leading-6 text-gray-700 flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-brand-500 w-5 h-5 mr-2"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Licensed &amp; Insured
                  </li>
                </ul>
              </div>
            </div>
            <ServicesOffered />
            <div className="md:mt-0 mt-6">
              <h4 className="text-sm leading-5 font-bold tracking-wider text-gray-700 uppercase font-display">
                Areas Served
              </h4>
              <AreasServed serviceArea={data.allContentfulServiceArea} />
            </div>
          </div>
         
          <div className="mt-3 border-t border-gray-200 pt-8">
            <p className="text-base leading-6 text-gray-800">
              &copy; {new Date().getFullYear()} {companynamefull}. All Rights Reserved.
              <span className="text-gray-600 text-sm block">
                <span>{altslogan}. </span>

                <span itemScope className="block md:inline" itemType="https://schema.org/Product">
                  <span itemProp="name">{data.site.siteMetadata.title}</span>{" "}
                  <span itemScope itemProp="aggregateRating" itemType="https://schema.org/AggregateRating">
                    rated <span itemProp="ratingValue">{REVIEW_AVG}</span> / 5 based on{" "}
                    <span itemProp="reviewCount">{TOTAL_REVIEW_COUNT}</span> reviews.
                  </span>
                </span>
                {isHomePage ? (
                  <>
                    <span>
                      {" "}
                      Web Design &amp; Marketing by{" "}
                      <a
                        target="_blank"
                        href="https://www.webperfex.com/?utm_source=westcoasthydronics&utm_medium=referral"
                        style={{ color: "rgb(136, 136, 136)" }}
                      >
                        WebPerfex
                      </a>
                    </span>
                  </>
                ) : null}
              </span>
            </p>
            <div className="text-gray-600  py-2 flex">
              <Link to={"/privacy-policy"} title={"Privacy Policy"} className=" hover:text-brand-600">
                Privacy Policy
              </Link>
              <Link to={"/contact"} title={"Contact Us"} className="ml-3 hover:text-brand-600">
                Contact Us
              </Link>
            </div>
          </div>

          <div>
            <CopyrightContainer ref={sidingCitiesLinksRef}>
              <CopyrightText className="footercities font-normal">
                West Coast Hydronics & Plumbing serves the following cities:&nbsp;
                <Link to={"/"} title={"Home Remodeling Sacramento CA"}>
                  Sacramento
                </Link>
                {", "}
                {data.allContentfulServiceAreaAll.edges.map(({ node: { id, location, slug } }, i) => (
                  <>
                    <Link key={id} to={`/water-heaters-${slug}/`} title={`${location} Water Heaters`}>
                      {location}
                    </Link>
                    {", "}
                  </>
                ))}

              </CopyrightText>
            </CopyrightContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
